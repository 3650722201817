
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponseBase } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { HeaderService } from '../shared/header.service';
@Injectable()
export class MetaManagerService {
    private url = environment.urlpath;
    constructor(private http: HttpClient, private router: Router, private headerSrv: HeaderService) { }

    // GLOBAL TEMPLATES BUILDER
    getMyIntelTemplatesById(selTempId: number) {
        let Params = new HttpParams();
        Params = Params.append('id', selTempId.toString());
        return this.http.get(`${this.url}/metamanager/getinteltemplates`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getMyIntelTemplates() {
        return this.http.get(`${this.url}/metamanager/getmyinteltemplates`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetReportsFrequency() {
        return this.http.get(`${this.url}/user/getReportFreq`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetMyIntelTemplateCompareData() {
        return this.http.get(`${this.url}/metamanager/getmyinteltemplatescompare`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetCompaniesBranches() {
        return this.http.get(`${this.url}/metamanager/getCompaniesBranches`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    RequestCIOPreviewOLD(parentId: number, relId: number, selFrequeny: any, imvselect: string, templateId: number, iMvSelectModify: boolean) {
        const body = new FormData();
        body.append('type', 'preview')
        body.append('parentId', parentId.toString());
        body.append('imvselect', imvselect.toString());
        body.append('frequency', selFrequeny.toString());
        body.append('template', templateId.toString());
        body.append('relationshipId', relId.toString());
        body.append('iMvSelectModify', iMvSelectModify.toString());
        return this.http.post(`${this.url}/user/requestMyIntelReport`, body, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    RequestMappedReportJSON(parentId: number, relId: number, selFrequeny: any, imvselect: string, templateId: number, iMvSelectModify: boolean) {
        const body = new FormData();
        body.append('type', 'previewraw')
        body.append('parentId', parentId.toString());
        body.append('imvselect', imvselect.toString());
        body.append('frequency', selFrequeny.toString());
        body.append('template', templateId.toString());
        body.append('relationshipId', relId.toString());
        body.append('iMvSelectModify', iMvSelectModify.toString());
        return this.http.post(`${this.url}/user/requestMyIntelReport`, body, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    async RequestCIOPreview(type: string,parentId: number, relId: number, selFrequeny: any, imvselect: string, templateId: number, iMvSelectModify: boolean, previewType: string[], ai_filter: boolean, amble?: string, reportHeaderImage?: string, sentTo?: string[]) {
        const body = new FormData();
        body.append('type', type)
        body.append('parentId', parentId.toString());
        body.append('imvselect', imvselect.toString());
        body.append('frequency', selFrequeny.toString());
        body.append('template', templateId.toString());
        body.append('relationshipId', relId.toString());
        body.append('iMvSelectModify', iMvSelectModify.toString());
        body.append('ai_filter', ai_filter.toString());
        if(sentTo){
            body.append('sentto', sentTo.join());   
        }
        if (amble) {
            body.append('amble', amble);
        };
        if (reportHeaderImage) {
            body.append('reportLogo', reportHeaderImage);
        };
        let myIntelReport: any = await this.http.post(`${this.url}/user/requestMyIntelReport`, body,
            { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler)).toPromise();
        // RETURN IF TYPE PREVIEW/SENT
        if(myIntelReport && type === 'previewsent'){
            return {previewsent: myIntelReport.requestMyIntelReport };
        }
        if (myIntelReport && previewType.includes('HTML') && !previewType.includes('PDF')) {
            // HTML ONLY
            return { html: myIntelReport.requestMyIntelReport.data, err: myIntelReport.requestMyIntelReport.err };
        } else if (myIntelReport && !previewType.includes('HTML') && previewType.includes('PDF')) {
            // PDF ONLY
            if (myIntelReport) {
                let apiKey: string = 'api_public_5bc8453d1cae423bb7b26f240abcb722';
                let _headers = new HttpHeaders();
                _headers = _headers.append('Authorization', `Token: ${apiKey}`);
                _headers = _headers.append('Content-Type', 'application/json');
                let body = JSON.stringify({ htmlCode: myIntelReport.requestMyIntelReport.data });
                let pdfBlob = await this.http.post(` https://api.sejda.com/v2/html-pdf`, body, { headers: _headers, responseType: 'blob' }).toPromise();
                return { pdf: pdfBlob, err: myIntelReport.requestMyIntelReport.err };
            }
        }else {
            // BOTH
            if (myIntelReport) {
                let apiKey: string = 'api_public_5bc8453d1cae423bb7b26f240abcb722';
                let _headers = new HttpHeaders();
                _headers = _headers.append('Authorization', `Token: ${apiKey}`);
                _headers = _headers.append('Content-Type', 'application/json');
                let body = JSON.stringify({ htmlCode: myIntelReport.requestMyIntelReport.data });
                let pdfBlob = await this.http.post(` https://api.sejda.com/v2/html-pdf`, body, { headers: _headers, responseType: 'blob' }).toPromise();
                return { pdf: pdfBlob, html: myIntelReport.requestMyIntelReport.data };
            }
        }
    }
    SubmitMyIntelTemplates(myIntelTemplateBody) {
        return this.http.post(`${this.url}/metamanager/myinteltemplates`, JSON.stringify(myIntelTemplateBody),
            { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    getCombineTypes() {
        return this.http.get(`${this.url}/metamanager/getCombineTypes`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getCombineTypeByName(typeName: string) {
        let Params = new HttpParams();
        Params = Params.append('type', typeName);
        return this.http.get(`${this.url}/metamanager/getCombines`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getNewsType() {
        return this.http.get(`${this.url}/metamanager/getNewsType`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getCombineBranches() {
        return this.http.get(`${this.url}/metamanager/getCombineBranches`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getCombineSections() {
        return this.http.get(`${this.url}/metamanager/getCombineSections`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getAIReportSections() {
        return this.http.get(`${this.url}/metamanager/getAIReportSections`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    filterEdit(gblRelId: number, filterBody: any) {
        const body = {
            id: gblRelId,
            body: filterBody
        }
        return this.http.post(`${this.url}/metamanager/filterEdit`, JSON.stringify(body), { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    SaveGlobalFilter(filterBody: any) {
        const body = {
            body: filterBody
        }
        return this.http.post(`${this.url}/metamanager/saveGlobalFilter`, JSON.stringify(body), { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetGlobalFilter() {
        return this.http.get(`${this.url}/metamanager/getGlobalFilter`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getFilterById(filterId: number) {
        let Params = new HttpParams();
        Params = Params.append('id', filterId.toString());
        return this.http.get(`${this.url}/metamanager/getFilter`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }

    filterRelationshipOptions() {
        return this.http.get(`${this.url}/metamanager/filterRelationshipOptions`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }

    getMyIntelRelationship(parentId: number, optLevel: number, optWeight: number) {
        let Params = new HttpParams();
        Params = Params.append('level', optLevel.toString())
        Params = Params.append('weight', optWeight.toString())
        Params = Params.append('parentId', parentId.toString());
        return this.http.get(`${this.url}/user/getRelationship`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetMyIntelReport(parentId: number, relId: number) {
        let Params = new HttpParams();
        Params = Params.append('parentId', parentId.toString())
        Params = Params.append('relationshipId', relId.toString());
        return this.http.get(`${this.url}/user/getMyIntelReport`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetAllActiveParentSearch(query: string) {
        let Params = new HttpParams();
        Params = Params.append('q', query);
        return this.http.get(`${this.url}/metamanager/getAllActiveParent`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetCIOFilterOptions(parentId: number) {
        let Params = new HttpParams();
        Params = Params.append('level', '2');
        Params = Params.append('weight', '100');
        Params = Params.append('parentId', parentId.toString());
        Params = Params.append('onlyparent', 'true');
        return this.http.get(`${this.url}/user/filterOption`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetCIOTreeData(parentId: number, selectedBranch: { id: number, name: string }[]) {
        // const selectedBranchIds = selectedBranch.map((branchItem) => branchItem.id).join(',');
        let Params = new HttpParams();
        Params = Params.append('level', '2');
        Params = Params.append('weight', '100');
        Params = Params.append('parentId', parentId.toString());
        Params = Params.append('superuser', 'false');
        Params = Params.append('v', '2');
        Params = Params.append('selectedbranch', selectedBranch.map((branchItem) => branchItem.id).join(','));
        return this.http.get(`${this.url}/user/getRelationship`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetReciprocalWeight() {
        return this.http.get(`${this.url}/metamanager/weightOverOptions`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetGlobalTemplate(parentId: number) {
        let Params = new HttpParams();
        if (parentId) {
            Params = Params.append('parentId', parentId.toString());
        }
        return this.http.get(`${this.url}/metamanager/getRelGlobalTmpt`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    AddRelGlobalTemplate(relName: string, parentTypeId: number, childTypeId: number, relId: number, relDataSeries: boolean,
        relClientHide: boolean, relAlphaOrder: boolean, recWeight: number, sortWeight: number, relTemplates: any[], recName?: string) {
        const body = new FormData();
        body.append('name', relName)
        body.append('parentType', parentTypeId.toString())
        body.append('childType', childTypeId.toString())
        body.append('hide', relClientHide.toString())
        // body.append('branchAlphaOrder', relAlphaOrder.toString())
        body.append('dataSeries', relDataSeries.toString())
        if (relId && relId > 0) {
            body.append('id', relId.toString());
        }
        body.append('reciprocalWeight', recWeight.toString());
        body.append('sortWeight', sortWeight.toString());
        // reciprocalName (optional)
        if (recName) {
            body.append('reciprocalName', recName.toString());
        }
        // const tempIdArry = [];
        if (relTemplates) {
            body.append('template', JSON.stringify(relTemplates));
        }
        return this.http.post(`${this.url}/metamanager/relglobaltmpt`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    searchRelationValues(query: string, relId: number, alias?: boolean) {
        let Params = new HttpParams();
        Params = Params.append('q', query)
        if (relId === -1) {
            Params = Params.append('type', '0');
        } else {
            Params = Params.append('relationshipId', relId.toString());
        }
        if(alias){
            Params = Params.append('alias', alias.toString());
        }
        return this.http.get(`${this.url}/metamanager/valuesuggest`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler))
    }
    searchRelationTemplates(query: string, relId: number) {
        let Params = new HttpParams();
        Params = Params.append('q', query);
        if (relId) {
            Params = Params.append('relationshipId', relId.toString());
        }
        return this.http.get(`${this.url}/metamanager/templatesuggest`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler))
    }
    SearchDataSeries(query: string) {
        let Params = new HttpParams();
        Params = Params.append('q', query);
        return this.http.get(`${this.url}/metamanager/datasuggest`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler))
    }
    getRelationtypes() {
        return this.http.get(`${this.url}/metamanager/relationtype`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getMetaRelationshipValues(parentId: number, relationshipId: number) {
        let Params = new HttpParams();
        Params = Params.append('relationshipId', relationshipId.toString())
        Params = Params.append('parentId', parentId.toString());
        Params = Params.append('noorder', 'true');
        return this.http.get(`${this.url}/metamanager/getRelationshipValue`,
            { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    AddMetaRelationshipValue(relationshipId: number, reciLevel: { [key: string]: any }, id: any[], templates: any[],
        delReciproVal?: number[], updateVal?: string[], optLevel?: number, optWeight?: number) {
        const body = new FormData();
        body.append('relationshipId', relationshipId.toString())
        body.append('level', reciLevel.level.toString())
        if (delReciproVal) {
            if (delReciproVal.length) {
                body.append('delReciproVal', delReciproVal.join())
            }
        }
        if (updateVal) {
            if (updateVal.length) {
                body.append('updateVal', updateVal.join())
            }
        }
        if (optLevel) {
            body.append('optLevel', optLevel.toString())
        }
        if (optWeight) {
            body.append('optWeight', optWeight.toString())
        }
        body.append('id', id.join());
        body.append('template', templates.join());
        return this.http.post(`${this.url}/metamanager/relationshipvalue`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    // tslint:disable-next-line:max-line-length
    addMetaRelationships(relationShipName: string, childType: number, parentId: number, reciLevel: { [key: string]: any }, isAlphaOrder: boolean, isLeftSide: boolean, hideRel?: boolean,
        hideDataSeries?: boolean, reciprocalName?: string, reciWeight?: number, sortWeight?: number, optLevel?: number, optWeight?: number,
        chk2del?: boolean, relationshipId?: number, delRecipro?: boolean) {
        const body = new FormData();
        if (chk2del) {
            body.append('chk2del', chk2del.toString())
        }
        if (relationshipId) {
            body.append('id', relationshipId.toString())
        }
        if (reciprocalName) {
            body.append('reciprocal', reciprocalName)
        }
        if (reciWeight) {
            body.append('reciprocalWeight', reciWeight.toString())
        }
        if (sortWeight) {
            body.append('sortWeight', sortWeight.toString())
        }
        if (delRecipro) {
            body.append('delRecipro', delRecipro.toString())
        }
        if (optLevel) {
            body.append('optLevel', optLevel.toString())
        }
        if (optWeight) {
            body.append('optWeight', optWeight.toString())
        }
        if (hideRel) {
            body.append('hide', hideRel.toString())
        }
        if (hideDataSeries) {
            body.append('dataSeries', hideDataSeries.toString())
        }
        body.append('name', relationShipName)
        body.append('level', reciLevel.level.toString())
        body.append('childType', childType.toString())
        body.append('parentId', parentId.toString())
        body.append('branchAlphaOrder', isAlphaOrder.toString());
        body.append('leftside', isLeftSide.toString());
        return this.http.post(`${this.url}/metamanager/addrelationship`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getMetaRelationshipById(parentId: number, selReciLevel: { [key: string]: any }, reciWeight: number) {
        let Params = new HttpParams();
        Params = Params.append('level', selReciLevel.level.toString())
        Params = Params.append('weight', reciWeight.toString())
        Params = Params.append('parentId', parentId.toString());
        return this.http.get(`${this.url}/metamanager/getRelationship`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getMetaManSearchSuggest(query) {
        return this.http.get(this.url + '/metamanager/searchsuggest?q=' + query, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getMetaValueByTypeId(metatypeid: number) {
        const body = new FormData();
        body.append('metatypeid', metatypeid.toString());
        return this.http.post(`${this.url}/metamanager/metavalues`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getAllMetaTypes() {
        return this.http.get(`${this.url}/metamanager/metatypes`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    addEditlMetaTypes(mtypeid: number, mtypeUpdatedName: string) {
        const body = new FormData();
        if (typeof mtypeid !== 'undefined') {
            body.append('id', mtypeid.toString());
        }
        body.append('name', mtypeUpdatedName);
        return this.http.post(`${this.url}/metamanager/metatype`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    getMetaValueById(mvid: number) {
        const body = new FormData();
        body.append('mvid', mvid.toString());
        return this.http.post(`${this.url}/metamanager/metavalue`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    AddMetaValue(selMetaTypeId: number, newMetaValueName: string) {
        const body = new FormData();
        body.append('metaTypeId', selMetaTypeId.toString());
        body.append('name', newMetaValueName);
        return this.http.post(`${this.url}/metamanager/addvalue`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    updateMetaValueData(mvid: number, value: string, mtypeId: number, legId: number) {
        const body = new FormData();
        body.append('id', mvid.toString())
        body.append('value', value)
        body.append('metaTypeId', mtypeId.toString())
        body.append('legacyId', legId.toString());
        return this.http.post(`${this.url}/metamanager/editmetavalue`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    deleteMetaValueById(metaValueId: number) {
        let Params = new HttpParams();
        Params = Params.append('id', metaValueId.toString())
        Params = Params.append('delete', 'true');
        return this.http.delete(`${this.url}/metamanager/editmetavalue`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    deleteSeletedMVKeyword(mvid: number, mtaxpk: number, parent: number) {
        let Params = new HttpParams();
        Params = Params.append('mvid', mvid.toString())
        Params = Params.append('mtaxpk', mtaxpk.toString())
        Params = Params.append('parent', parent.toString());
        return this.http.delete(`${this.url}/metamanager/delvaluetax`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    addSelectedMVKeyword(mvid: number, metarelationship: number, button: string, metavalueto: any[]) {
        const body = new FormData();
        body.append('metarelationship', metarelationship.toString())
        body.append('mvid', mvid.toString())
        body.append('button', button.toString())
        body.append('metavalueto', metavalueto.toString());
        return this.http.post(`${this.url}/metamanager/addvaluetax`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    AddAliasToSelectedMV(btnType: string, relationshipId: number, value: string) {
        const body = new FormData();
        body.append('btnType', btnType);
        body.append('relationshipId', relationshipId.toString());
        body.append('value', value);
        return this.http.post(`${this.url}/metamanager/metavaluerel`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    searchUnUsedMetaValue(mvid: number, metarelationship: number, button: string, nodata: boolean, q: string) {
        const body = new FormData();
        body.append('mvid', mvid.toString())
        body.append('metarelationship', metarelationship.toString())
        body.append('button', button.toString())
        body.append('nodata', nodata.toString())
        body.append('q', q);
        return this.http.post(`${this.url}/metamanager/getmetavalueunused`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    AddMetaRelationships(name: string, pTypeId: number, cTypeId: number) {
        const body = new FormData();
        body.append('name', name)
        body.append('parentType', pTypeId.toString())
        body.append('childType', cTypeId.toString());
        return this.http.post(`${this.url}/metamanager/metarel`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    EditMetaRelationships(metaRelId: number, name: string) {
        const body = new FormData();
        body.append('id', metaRelId.toString())
        body.append('name', name);
        return this.http.post(`${this.url}/metamanager/metarel`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    CloneMyIntelTemplate(selectedTemplateId: number, newTemplateName: string) {
        const body = new FormData();
        body.append('id', selectedTemplateId.toString());
        body.append('name', newTemplateName);
        return this.http.post(`${this.url}/metamanager/myinteltemplatesclone`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    async PreviewPDF(html, viewPortWidthNumber: number, pageOrientation?: string) {
        let apiKey: string = 'api_public_5bc8453d1cae423bb7b26f240abcb722';
        let _headers = new HttpHeaders();
        _headers = _headers.append('Authorization', `Token: ${apiKey}`);
        _headers = _headers.append('Content-Type', 'application/json');
        let body = JSON.stringify({ htmlCode: html, viewportWidth: viewPortWidthNumber, pageOrientation: pageOrientation ? pageOrientation : 'auto' });
        return await this.http.post(` https://api.sejda.com/v2/html-pdf`, body, { headers: _headers, responseType: 'blob' }).toPromise();
    }
    GetGlobalTemplateMap() {
        return this.http.get(`${this.url}/metamanager/globaltemplatemap`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetComapniesMap(selectedMapWeight: number) {
        let Params = new HttpParams();
        Params = Params.append('weight', selectedMapWeight.toString());
        return this.http.get(`${this.url}/metamanager/companymap`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    SearchConflictMetaValueQ(metaTypeId: number, query: string) {
        let Params = new HttpParams();
        Params = Params.append('metaTypeId', metaTypeId.toString());
        Params = Params.append('q', query);
        return this.http.get(`${this.url}/metamanager/metavalueq`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    MergeMetaValue(selectedMetaValueId: number, destinationIds: number[], conflictIds: number[]) {
        const body = new FormData();
        body.append('source', selectedMetaValueId.toString());
        body.append('destination', destinationIds.join());
        body.append('id', conflictIds.join());
        return this.http.post(`${this.url}/metamanager/mergemetavalue`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetBotModules() {
        return this.http.get(`${this.url}/metamanager/getbotmodule`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetBotModulesById(moduleId: number) {
        let Params = new HttpParams();
        Params = Params.append('id', moduleId.toString());
        return this.http.get(`${this.url}/metamanager/getbotmodule`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    AddUpdateBotModules(BotModuleJSON) {
        let body;
        if (BotModuleJSON.id === -1) {
            let copyJSON = JSON.parse(JSON.stringify(BotModuleJSON));
            delete copyJSON.id;
            body = JSON.stringify(copyJSON);
        } else {
            body = JSON.stringify(BotModuleJSON);
        }
        return this.http.post(`${this.url}/metamanager/botmodule`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    DeleteBotModules(moduleId: number) {
        const httpOptions = {
            headers: this.headerSrv.returnHeader(),
            body: JSON.stringify({id: moduleId})
        };
        return this.http.delete(`${this.url}/metamanager/botmodule`, httpOptions).pipe(catchError(this.headerSrv._errorHandler));
    }
    BotPreview(moduleId: number, toId: number, previewFreq: number, sentTo: {id: number, name: string}) {
        const body = new FormData();
        body.append('id', moduleId.toString());
        body.append('to', toId.toString());
        if(previewFreq){
            body.append('freq', previewFreq.toString());
        }
        if(sentTo){
            body.append('userId', sentTo.id.toString());
        }
        return this.http.post(`${this.url}/metamanager/botpreview`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    BotModuleSuggest(q: string, groupType: string) {
        const body = new FormData();
        body.append('q', q);
        body.append('group', groupType);
        return this.http.post(`${this.url}/metamanager/botmodulesuggest`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetVerticalChildren(mvid: number){
        let Params = new HttpParams();
        Params = Params.append('mvid', mvid.toString());
        return this.http.get(`${this.url}/metamanager/getVerticalChildren`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    SaveVerticalChildren(mvid: number, selectedVertical: {id: number, name: string}[]) {
        const body = new FormData();
        body.append('mvid', mvid.toString());
        body.append('selected', selectedVertical.map(vertical=>vertical.id).toString());
        return this.http.post(`${this.url}/metamanager/saveverticalchildren`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    DeleteIntelTemplates(id: number) {
        const body = new FormData();
        body.append('id', id.toString());
        return this.http.post(`${this.url}/metamanager/delinteltemplates`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    NewsEdgeSources(){
        return this.http.get(`${this.url}/metamanager/newsedge_source?v=2`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    LazyNewsEdgeSimulation(pageNo: number, pageSize: number, source?:{id: string,name: string,type: number}[], sort?: string, sortOrder?: number, headline?: string, score?:number,entityArr?:{name:string}[],
         taxonomyArr?:{name: string}[], postProcessItem?:string, businessRelavanceItem?:string){
        const body = new FormData();
        body.append('pageSize', pageSize.toString());
        body.append('pageNo', pageNo.toString());
        if(source){
            body.append('source', source.map(src=>src.id).join());
        }
        if(sort){
            body.append('sort', sort.toString());
        }
        if(score){
            body.append('score', score.toString());
        }
        if(typeof sortOrder === 'number'){
            body.append('sortOrder', sortOrder.toString());
        }
        if(headline){
            body.append('headline', headline);
        }
        if(entityArr){
            body.append('entity', entityArr.map(enItem=>enItem.name).join('|'));
        }
        if(taxonomyArr){
            body.append('taxonomy', taxonomyArr.map(taxItem=>taxItem.name).join('|'));
        }
        if(postProcessItem){
            body.append('post_process_score', postProcessItem.toString());
        }
        if(businessRelavanceItem){
            body.append('business_relevance', businessRelavanceItem.toString());
        }
        return this.http.post(`${this.url}/metamanager/newsedge_simulation`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetNewsEdgeSimStats(){
        return this.http.get(`${this.url}/metamanager/newsedge_simulation_stat`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetNewsEdgeSimPostStats(){
        let Params = new HttpParams();
        Params = Params.append('post_process_score', 'true');
        return this.http.get(`${this.url}/metamanager/newsedge_simulation_stat`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetNewsEdgeOperationStats(startDate: string, endDate: string,userId?: {id:number, name: string}){
        const body = new FormData();
        body.append('startdate', startDate);
        body.append('enddate', endDate);
        if(userId){
            body.append('userId', userId.id.toString());
        }
        return this.http.post(`${this.url}/metamanager/biaggusage`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetNewsEdgeOperationsGateKeepers(){
        return this.http.get(`${this.url}/metamanager/biaggusage_users`, {headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetNewsEdgeSimEntityTaxonomoy(selectionKey: string){
        let Params = new HttpParams();
        Params = Params.append('selection', selectionKey);
        return this.http.get(`${this.url}/metamanager/newsedge_entity_taxonomy_value`, {params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    RefreshNewsEdgeSimScore(newsEdgeArticleId: string){
        let Params = new HttpParams();
        Params = Params.append('id', newsEdgeArticleId);
        return this.http.get(`${this.url}/metamanager/newsedge_score_calc`, {params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetBIPublishRules(){
        return this.http.get(`${this.url}/metamanager/bi_publish_rules`, {headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    PostTestBIPublishRules(ruleId: number, storyId: string, ResetMongoStoryFinger: boolean ,storyHeadline: string, storyContent: string, i2SourceName: string, sourceCode: string) {
        const body = new FormData();
        body.append('ruleId', ruleId.toString());
        body.append('id', storyId);
        body.append('ResetMongoStoryFinger', ResetMongoStoryFinger.toString());
        body.append('StoryHeadLine', storyHeadline);
        body.append('StoryContent', storyContent);
        body.append('I2SourceName', i2SourceName);
        body.append('SourceCode', sourceCode);
        return this.http.post(`${this.url}/metamanager/test_publish_rule`,body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    PostBIAliasTest(storyId: string, storyHeadline: string) {
        const body = new FormData();
        body.append('id', storyId);
        body.append('StoryHeadLine', storyHeadline);
        body.append('v', '2');
        return this.http.post(`${this.url}/metamanager/test_alias`,body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    PostBIPublishRules(gblRelId: number, filterBody: any) {
        const body = {
            id: gblRelId,
            body: filterBody
        }
        return this.http.post(`${this.url}/metamanager/bi_publish_rules`, JSON.stringify(body), { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    PostPutBIPublishRules(action: string, submitData: any){
        if(action === 'delete'){
            const httpOptions = {
                headers: this.headerSrv.returnHeader(),
                body: JSON.stringify(submitData)
            };
            return this.http[action](`${this.url}/metamanager/bi_publish_rules`, httpOptions).pipe(catchError(this.headerSrv._errorHandler));
        } else {
            return this.http[action](`${this.url}/metamanager/bi_publish_rules`, JSON.stringify(submitData), { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
        }
    }

    GetNewsEdgeTrustedSources(){
        return this.http.get(`${this.url}/metamanager/trusted_sources`, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetMetaAlias(metaValueId: number){
        let Params = new HttpParams();
        Params = Params.append('id', metaValueId.toString());
        return this.http.get(`${this.url}/metamanager/meta_alias`, {params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    PutMetaAlias(metaValueId: number, aliasStringArray: string[]){
        let body = {id: metaValueId,aliases:aliasStringArray};
        return this.http.put(`${this.url}/metamanager/meta_alias`, JSON.stringify(body), { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    TestSERPFeed(text: string, story_size: number){
        let Params = new HttpParams();
        Params = Params.append('q', text);
        if(story_size !== null){
            Params = Params.append('story_size', story_size.toString());
        }
        return this.http.get(`${this.url}/metamanager/serp_test`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    TestPageMonitorFeed(SelPMFeedName: string,url_type: 'html'|'html2'|'api'|'rss'|'sshot'|'zyte',url: string, outerIdName: string, DOM_Title: string, DOM_Link: string, DOM_Date: string, DOM_Content: string,DOM_RemoveElem:string[],DOM_Content2: string,DateRegex:string,
    DateFormat: string, wait_for_css: string,wait_for_css_content: string,wait: string,selectedCookie: string, Body: string, premium_proxy: null|number, stealth_proxy: null|number, render_js: boolean, slugURL: string, dynamic_url: boolean ,mainProfileMValueId: number, story_size: number,
    js_scenario: string){
        const body = new FormData();
         body.append('url_type', url_type);
         body.append('url', url);
         body.append('wait', wait);
         body.append('mvalueId', mainProfileMValueId.toString());
        if(premium_proxy){
            body.append('premium_proxy', premium_proxy.toString());
        }
        if(stealth_proxy){
            body.append('stealth_proxy', stealth_proxy.toString());
        }
        if(typeof render_js === 'boolean'){
            body.append('render_js', render_js.toString());
        }
        if(slugURL){
            body.append('slugURL', slugURL.toString());
        }
        if(typeof dynamic_url === 'boolean'){
            body.append('dynamic_url', dynamic_url.toString());
        }
        if(DOM_RemoveElem){
            body.append('DOM_RemoveElem', DOM_RemoveElem.join());
        }
        if((url_type === 'html' || url_type === 'html2' || url_type === 'api' || url_type === 'rss')){
            body.append('outerIdName', outerIdName);
            body.append('DOM_Date', DOM_Date);
            body.append('DOM_Title', DOM_Title);
            body.append('DOM_Link', DOM_Link);
            body.append('DOM_Content', DOM_Content);
            body.append('DOM_Content2', DOM_Content2);
            body.append('DateRegex', DateRegex);
            body.append('DateFormat', DateFormat);
            body.append('wait_for_css', wait_for_css);
            body.append('wait_for_css_content', wait_for_css_content);
            body.append('Body', Body);
            body.append('cookie', selectedCookie?selectedCookie:'');
            if(story_size !== null){
                body.append('story_size', story_size.toString());
            }
            if(js_scenario){
                body.append('js_scenario', js_scenario.toString());
            }
        }else if(url_type === 'zyte'){
            body.append('DateRegex', DateRegex);
            body.append('DateFormat', DateFormat);
            if(story_size !== null){
                body.append('story_size', story_size.toString());
            }
        }else{
            body.append('name_str', SelPMFeedName);
        }
        return this.http.post(`${this.url}/metamanager/test_page_monitor`,body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    TestContentPageMonitor(url_type: 'html'| 'html2' |'api'|'rss'|'sshot'|'zyte',url: string, outerIdName: string, DOM_Title: string, DOM_Link: string, DOM_Date: string, DOM_Content: string,DOM_RemoveElem:string[],DOM_Content2: string,DateRegex:string,
    selectedCookie: string, Body: string, premium_proxy: null|number, stealth_proxy: null|number, render_js: boolean, mainProfileMValueId: number){
        const body = new FormData();
        body.append('url_type', url_type);
        body.append('url', url);
        body.append('outerIdName', outerIdName);
        body.append('DOM_Date', DOM_Date);
        body.append('DOM_Title', DOM_Title);
        body.append('DOM_Link', DOM_Link);
        body.append('DOM_Content', DOM_Content);
        body.append('mvalueId', mainProfileMValueId.toString());
        if(DOM_RemoveElem){
            body.append('DOM_RemoveElem', DOM_RemoveElem.join());
        }
        body.append('DOM_Content2', DOM_Content2);
        body.append('DateRegex', DateRegex);
        body.append('Body', Body);
        body.append('cookie', selectedCookie?selectedCookie:'');
        if(premium_proxy){
            body.append('premium_proxy', premium_proxy.toString());
        }                 
        if(stealth_proxy){
            body.append('stealth_proxy', stealth_proxy.toString());
        } 
        if(typeof render_js === 'boolean'){
            body.append('render_js', render_js.toString());
        }                
        return this.http.post(`${this.url}/metamanager/test_content`,body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    TestTryPageMonitorFeed(url_type: 'html'| 'html2' |'api'|'rss'|'sshot'|'zyte',testURL: string, mainProfileMValueId: number,wait_for_css?: string, wait_for_css_content?: string,wait?: string,premium_proxy?: null|number,stealth_proxy?: null|number, render_js?: boolean,js_scenario?: string){
        let Params = new HttpParams();
        Params = Params.append('url', testURL);
        Params = Params.append('url_type', url_type);
        Params = Params.append('mvalueId', mainProfileMValueId.toString());
        if(wait){
            Params = Params.append('wait', wait);
        }
        if(wait_for_css){
            Params = Params.append('wait_for_css', wait_for_css);
        }
        if(wait_for_css_content){
            Params = Params.append('wait_for_css_content', wait_for_css_content);
        }
        if(premium_proxy){
            Params = Params.append('premium_proxy', premium_proxy.toString());
        }
        if(stealth_proxy){
            Params = Params.append('stealth_proxy', stealth_proxy.toString());
        }
        if(typeof render_js === 'boolean'){
            Params = Params.append('render_js', render_js.toString());
        }
        if(js_scenario){
            Params = Params.append('render_js', render_js.toString());
        }
        return this.http.get(`${this.url}/metamanager/url_preview`, {params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    TestDOMPageMonitorFeed(url_type: 'html'| 'html2' |'api'|'rss'|'sshot'|'zyte',testURL: string,dynamic_url: boolean,outerDOM: string, selectedDOMInfo: string, selectedCookie: string, mainProfileMValueId: number,
        story_size: number,wait_for_css?: string, wait_for_css_content?: string,wait?:string,premium_proxy?: null|number,stealth_proxy?: null|number,render_js?: boolean,location?: string,js_scenario?: string){
        const body = new FormData();
        body.append('url', testURL);
        body.append('url_type', url_type);
        body.append('outerDOM', outerDOM);
        body.append('dom', selectedDOMInfo);
        body.append('cookie', selectedCookie?selectedCookie:'');
        body.append('mvalueId', mainProfileMValueId.toString());
        if(typeof dynamic_url === 'boolean'){
            body.append('dynamic_url', dynamic_url.toString());
        }
        if(story_size !== null){
            body.append('story_size', story_size.toString());
        }
        if(location && location === 'DOM_Date'){
            body.append('isText', 'true');
        }
        if(wait){
            body.append('wait', wait);
        }
        if(wait_for_css){
            body.append('wait_for_css', wait_for_css);
        }
        if(wait_for_css_content){
            body.append('wait_for_css_content', wait_for_css_content);
        }
        if(premium_proxy){
            body.append('premium_proxy', premium_proxy.toString());
        }
        if(stealth_proxy){
            body.append('stealth_proxy', stealth_proxy.toString());
        }
        if(typeof render_js === 'boolean'){
            body.append('render_js', render_js.toString());
        }
        if(js_scenario){
            body.append('js_scenario', js_scenario.toString());
        }
        return this.http.post(`${this.url}/metamanager/test_dom`,body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetAllKillingRules(){
        return this.http.get(`${this.url}/metamanager/bi_killing_rules`, {headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    AddEditDeleteKillingRules(action: 'post'|'put'|'delete', submitData: any){
        if(action === 'delete'){
            const httpOptions = {
                headers: this.headerSrv.returnHeader(),
                body: JSON.stringify(submitData)
            };
            return this.http[action](`${this.url}/metamanager/bi_killing_rules`, httpOptions).pipe(catchError(this.headerSrv._errorHandler));
        } else {
            return this.http[action](`${this.url}/metamanager/bi_killing_rules`, JSON.stringify(submitData), { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
        }
    }
    TestKillingRules(ruleData: any, storyId: string, storyObj: any) {
        let body: {rule: any, id?: string, story?: any} = {rule: ruleData};
        if(storyId){
            body.id = storyId;
        }else{
            body.story = storyObj;
        }
        return this.http.post(`${this.url}/metamanager/test_killing_rule`, JSON.stringify(body), { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetAllAIAssistants(){
        return this.http.get(`${this.url}/metamanager/getAIAssistants`, {headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetBotUsers(companyId: number) {
        let Params = new HttpParams();
        Params = Params.append('companyId', companyId.toString());
        return this.http.get(`${this.url}/metamanager/getbotuser`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    TestAIPrompt(BotModuleJSON){
        let body;
        if (BotModuleJSON.id !== -1) {
            body = JSON.stringify(BotModuleJSON);
        }else{
            return;
        }
        return this.http.post(`${this.url}/metamanager/bottest`, body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetKeywordAliasByMV(mv_id:number) {
        let Params = new HttpParams();
        Params = Params.append('mv_id', mv_id.toString());
        return this.http.get(`${this.url}/metamanager/keyword_alias_get`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    Template_Builder_Lock_Unlock(template_id: number,isLock: boolean,unlock_key:number){
        let Params = new HttpParams();
        Params = Params.append('id', template_id.toString());
        Params = Params.append('lock', isLock.toString());
        if(isLock === false){
            Params = Params.append('unlock_key', unlock_key?unlock_key.toString():'');
        }
        return this.http.get(`${this.url}/metamanager/template_lock`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    Template_Builder_Request_Unblock(template_id: number){
        let Params = new HttpParams();
        Params = Params.append('id', template_id.toString());
        Params = Params.append('lock', 'false');
        Params = Params.append('unlock_key', '');
        return this.http.get(`${this.url}/metamanager/template_lock`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    } 
    GetMapDescriptionNotes(mvId: number){
        let Params = new HttpParams();
        Params = Params.append('id', mvId.toString());
        return this.http.get(`${this.url}/metamanager/map_note`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    PostMapDescriptionNotes(mvId: number, notes: string){
        const body = new FormData();
        body.append('id', mvId.toString());
        body.append('note', notes);
        return this.http.post(`${this.url}/metamanager/map_note`,body, { headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetMapAINotesTest(){
        return this.http.get(`${this.url}/metamanager/map_note_test`, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    /**
     * GetMAPAINotesPreview
     * Sends a POST request to retrieve the preview of MPAI notes, dynamically appending 
     * any key-value pairs passed in the `preview_btn_req_param` object to the request body.
     *
     * @param {object} preview_btn_req_param - An object containing key-value pairs 
     *                                         to be added to the request body.
     */
    GetMAPAINotesPreview(preview_btn_req_param: { parentId: number; imvselect: string; frequency: number; relationshipId: number; iMvSelectModify: boolean; template: number; type: string; }) {
        // If no object is passed, exit the function
        if (!preview_btn_req_param) return;

        // Initialize FormData to build the request body
        const body = new FormData();

        // Iterate through each key in the preview_btn_req_param object and append to FormData
        Object.keys(preview_btn_req_param).forEach(key => {
            body.append(key, preview_btn_req_param[key].toString());
        });

        // Send the POST request with the constructed FormData and return the result
        return this.http.post(`${this.url}/user/requestMyIntelReport`, body, { headers: this.headerSrv.returnHeader()}).pipe(catchError(this.headerSrv._errorHandler));
    }

    /**
     * UpdateMapAINotes
     * Sends a POST request to update a specific MPAI note with the provided data.
     *
     * @param {object} noteObj - The note object containing the note, id, and production status.
     *                           Example:
     *                           {
     *                              "note": "New note content",
     *                              "id": 3699006,
     *                              "production": false
     *                           }
     */
    UpdateMapAINotes(noteObj: { note: string, id: number, production: boolean }) {
        // Ensure that the required data is provided
        if (!noteObj || !noteObj.id) {
            console.error('Invalid note object provided');
            return;
        }

        // Create the request body with the note object
        const body = new FormData();
        body.append('note', noteObj.note);
        body.append('id', noteObj.id.toString());
        body.append('production', noteObj.production.toString());

        // Send the POST request to update the note
        return this.http.post('https://api.industryintel.com/api/metamanager/map_note', body, {headers: this.headerSrv.returnHeader()}).pipe( catchError(this.headerSrv._errorHandler));
    }

}
