import { Injectable } from '@angular/core';
import { HeaderService } from '../shared/header.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
@Injectable()
export class QAManagerService {
    private url = environment.urlpath;
    constructor(private http: HttpClient, private router: Router, private _cookieService: CookieService, private headerSrv: HeaderService) { }

    /**
     * GET SPOT CHECKER QA LIST
     * 
     * @param {0 | 1 | 2 } type - type of request
     * - 0: EARLY (12AM-11:30)
     * - 1: MID (11:31-4:30PM)
     * - 2: LATE  (4:31PM-11:59PM)
     */
    GetSpotChecker(shiftTime: number) {
        let Params = new HttpParams();
        Params = Params.append('shift', shiftTime.toString());
        return this.http.get(`${this.url}/infoman/spot_checker`, { params: Params,headers: this.headerSrv.returnHeader() })
    }
    /**
     * GET EDITOR QA LIST
     * 
     * @param {0 | 1 | 2 } type - type of request
     * - 0: Published
     * - 1: Trash
     * - 2: Newsedge
     */
    GetEditorQAList(type: number){
        let Params = new HttpParams();
        if(type === 0){
            Params = Params.append('OnlyTrash', 'false');
            Params = Params.append('NewsEdge', 'false');
        }else if(type === 1){
            Params = Params.append('OnlyTrash', 'true');
            Params = Params.append('NewsEdge', 'false');
        }else if(type === 2){
            Params = Params.append('OnlyTrash', 'false');
            Params = Params.append('NewsEdge', 'true');
        }else {
            return;
        }
        return this.http.get(`${this.url}/infoman/editor_qa`, { params: Params,headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    /**
     * Perform an Editor QA Post action.
     * 
     * @param {number} id - The ID of the post.
     * @param {1 | 2 | 3 | 4} action_taken - The action taken on the post.
     * - 1: Looks Great
     * - 2: Stop Presses
     * - 3: Untrash
     * - 4: Confirm Trash
     * @param {string} [note] - Optional note for the action.
     * @param {number} [qa_rejection_id] - Optional QA rejection ID, required if action_taken is 2.
     */
    EditorQAPost(id: number, action_taken: 1 | 2 | 3 | 4, note?: string, qa_rejection_id?:number,new_priority?: {id: number, name: string}) {
        const body = new FormData();
        body.append('id', id.toString());
        body.append('action_taken', action_taken.toString());
        if(note && action_taken === 2){
            body.append('qa_rejection_id', qa_rejection_id.toString());
            body.append('note', note);
        }
        if(action_taken === 3){
            body.append('new_priority', new_priority.id.toString());
            body.append('v', '2');
        }
        return this.http.post(`${this.url}/infoman/editor_qa`, body, {headers: this.headerSrv.returnHeader() }).pipe(
            catchError(this.headerSrv._errorHandler));
    }
    GetAllDocumentPriorites(){
        let Params = new HttpParams();
        Params = Params.append('metaTypeId', '10');
        return this.http.get(`${this.url}/infoman/metavalueq`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    GetQARejectOptions(){
        let Params = new HttpParams();
        Params = Params.append('metaTypeId', '41810');
        return this.http.get(`${this.url}/infoman/metavalueq`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    InitQADuplicates(pageNo: number, pageSize: number){
        let Params = new HttpParams();
        Params = Params.append('pageNo', pageNo.toString());
        Params = Params.append('pageSize', pageSize.toString());
        return this.http.get(`${this.url}/infoman/editor_qa_duplicates`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    LazyLoadQADuplicates(pageNo: number, pageSize: number, tag: number, search: string){
        let Params = new HttpParams();
        Params = Params.append('pageNo', pageNo.toString());
        Params = Params.append('pageSize', pageSize.toString());
        if(typeof tag === 'number'){
            Params = Params.append('tag', tag.toString());
        }
        if(search){
            Params = Params.append('search', search);
        }
        return this.http.get(`${this.url}/infoman/editor_qa_duplicates`, { params: Params, headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    /**
     * Perform an Editor QA Post action.
     * 
     * @param {string} id - The ID of the post.
     * @param {1 | 2} action_taken - The action taken on the post.
     * - 1: Confirm
     * - 2: Publish
     * @param {string} note - Note required if action_taken is Publish.
     */
    EditorQADuplicatesPost(id: string, action_taken: 1 | 2, note?: string) {
        const body = new FormData();
        body.append('id', id);
        body.append('action_taken', action_taken.toString());

        if (action_taken === 2 && note) {
            body.append('note', note);
        }
        return this.http.post(`${this.url}/infoman/editor_qa_duplicates`,  body, {headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    /**
     * QA Pool: Get missing keywords history
     */
    QAPoolMissingKeywordsHistory() {
        return this.http.get(`${this.url}/infoman/missing_fingerprint_history`, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    /**
     * QA Pool: Get patents and stock qa
     */
    PatentStockQA() {
        return this.http.get(`${this.url}/infoman/patent_stock_qa`, {headers: this.headerSrv.returnHeader()}).pipe(catchError(this.headerSrv._errorHandler));
    }
    /**
     * BROKEN PM: Get a list of broken PM
     */
    BrokenPMList() {
        return this.http.get(`${this.url}/infoman/broken_pm`, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    /**
     * BROKEN PM: Get a list of broken PM
     */
    CompareNewsList() {
        return this.http.get(`${this.url}/infoman/compare_news`, { headers: this.headerSrv.returnHeader() }).pipe(catchError(this.headerSrv._errorHandler));
    }
    /**
     * Retrieves an Excel file containing a list of suggested keywords.
     * The response is handled as binary data and is expected to be downloaded as an Excel file.
     */
    GetKeywordAddedSuggestExcel() {
        return this.http.get(`${this.url}/infoman/keyword_added_suggest_excel`, {headers: this.headerSrv.returnHeader(), responseType: 'blob', observe: 'response' }).pipe(catchError(this.headerSrv._errorHandler));
    }
    /**
     * Retrieves an Excel file containing a list of suggested keywords.
     * The response is handled as binary data and is expected to be downloaded as an Excel file.
     */
    GetKeywordAddedSuggestTable() {
        return this.http.get(`${this.url}/infoman/keyword_added_suggest`, {headers: this.headerSrv.returnHeader()}).pipe(catchError(this.headerSrv._errorHandler));
    }
    /**
     * Retrieves an list of Newsedge Quries
     * The list is the current autopublish NE queries
     */
    GetAutoPublishNEQueryList() {
        return this.http.get(`${this.url}/infoman/ne_query_list`, {headers: this.headerSrv.returnHeader()}).pipe(catchError(this.headerSrv._errorHandler));
    }
}
